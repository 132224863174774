form {
    max-width: 600px;
    margin: 0 25px;
    margin-top: 20px;
    padding: 50px;
    border: 1px solid #1ab9ff;
    border-radius: 25px;
    background-color: #1ab9ff;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .contact-div {
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #FF4880;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 25px;
    border-radius: 10px;
  }
  
  button:hover {
    background-color: #273e56;
  }
  