.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5%; 
}

.text-content {
  max-width: 80%; 
  padding: 2%;
  text-align: center;
}

.animated-image {
  transition: all 0.2s linear;
  animation: slide 2s linear infinite;
  max-width: 80%;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(30%);
  }

  75% {
    transform: translateX(-30%);
  }

  100% {
    transform: translateX(0%);
  }
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.icon-left {
  display: grid;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.icon-right {
  display: grid;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.icon {
  margin: 10px;
}

.circle-background-book {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ABCD52;
  color: white;
  margin-right: 10px;
}

.circle-background-paint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1AB9FF;
  color: white;
  margin-right: 10px;
}

.circle-background-paint2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FFC000;
  color: white;
  margin-right: 10px;
}

.circle-background-cramp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FF6666;
  color: white;
  margin-right: 10px;
}

.icon-image {
  width: 35px;
  height: 35px;
}


/* Media Query para telas menores */
@media (max-width: 768px) {
  .content-container {
    margin: 10%; 
  }

  .text-content, .animated-image {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .icon-container {
    flex-direction: column; 
    align-items: flex-start; 
  }

  .icon {
    margin: 10px 0;
  }
}