body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.slider-container {
  width: 100%;
  height: 100%;
}

.image-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Largura da máscara para cobrir toda a imagem */
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255)); /* Gradiente à esquerda */
}

.slide-image {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  object-fit: cover; /* Ajusta o comportamento da imagem ao redimensionar */
}