.split-component {
  display: flex;
  height: 40vh;
  margin-bottom: 80px;
}

.left-section {
  flex: 1;
  background-color: #351c5a;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-section h2 {
  margin-bottom: -15px;
}

.main-image {
  width: 10px;
  height: 10px;
}

.right-section {
  flex: 1;
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.overlay {
  width: 100px;
  height: auto;
}


@media (max-width: 768px) {
  .split-component {
    height: auto;
    margin-bottom: 20px;
    display: block;
  }

  .left-section, .right-section {
    flex: 1;
  }

  .left-section {
  }

}