.mensagem-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1ab9ff;
    font-family: 'Fredoka One', sans-serif;
  }
  
  .mensagem {
    color: white;
    font-size: 36px;
    text-align: center;
  }
  
  .botao {
    background-color: #FF4880;
    color: white;
    padding: 10px 20px;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .botao:hover {
    background-color: #FF3566;
  }
  