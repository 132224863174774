.text-image-component {
  padding: 20px;
  margin-top: 120px;
}

.paragraphs-and-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.paragraphs-and-image p {
  margin-bottom: 10px;
}

.color-blocks {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.color-block {
  flex: 1;
  padding: 30px;
  margin: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column; /* Alterado para dispor os elementos em coluna */
  align-items: center; /* Alinha o texto verticalmente no centro */
  justify-content: center; /* Alinha o texto horizontalmente no centro */
}

.color-block h3 {
  margin-bottom: 10px; /* Adiciona espaço entre o título e o parágrafo */
}
  
.color-block:hover {
    transform: translateY(-10px);
  }