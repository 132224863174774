.balancing-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fcf9f4;
  padding: 20px;
}

.image-container {
  flex: 1;
  margin-right: 20px;
}

.image-container img {
  width: 100%;
  max-width: 100%;
  animation: bounce 2s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(-10px);
  }
}

.text-blocks {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.text-block {
  margin-bottom: 20px;
}

.text-block h3 {
  margin-bottom: 10px;
}

/* Estilos específicos para dispositivos móveis (até 767px de largura) */
@media (max-width: 767px) {
  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
    /* Adiciona margem inferior para separar a imagem dos textos */
  }

  .text-blocks {
    order: -1;
    /* Reordena os elementos para a imagem ficar acima do texto */
  }

  .balancing-component {
    flex-direction: column-reverse;
    /* Reordena os elementos para a imagem ficar acima do texto */
  }
}