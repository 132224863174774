.big-footer {
    background-color: #351c5a;
    color: white;
    padding: 80px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin-bottom: 10px;
  }
  
  .footer-logo {
    width: 150px; 
    margin-bottom: 50px;
  }
  
  .social-icons a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    margin-right: 10px;
  }
  
  .footer-menu {
    list-style: none;
    padding: 0;
  }
  
  .footer-menu li {
    margin-bottom: 5px;
  }
  
  .footer-menu a {
    color: white;
    text-decoration: none;
  }
  
  .contact-info span {
    font-weight: bold;
    display: block;
    margin-bottom: 300px;
  }
  
  .social-icon-style-instagram {
    color: #fff;
    background-color: #ff4880;
    border-radius: 50%;
    padding: 6px;
    margin: 0 0px;
  }

  .social-icon-style-youtube {
    color: #fff;
    background-color: #ffc000;
    border-radius: 50%;
    padding: 6px;
    margin: 0 0px;
  }

  .social-icon-style-facebook {
    color: #fff;
    background-color: #1ab9ff;
    border-radius: 50%;
    padding: 6px;
    margin: 0 0px;
  }

  .social-icon-style-whatsapp {
    color: #fff;
    background-color: #abcd52;
    border-radius: 50%;
    padding: 6px;
    margin: 0 0px;
  }

  .social-icon-style-tiktok {
    color: #fff;
    background-color: #1ab9ff;
    border-radius: 50%;
    padding: 6px;
    margin: 0 0px;
  }

  .arrow-icon::before {
    content: "\f054"; /* Código Unicode para a seta direita */
    font-family: FontAwesome;
    padding-right: 0.5em;
  }
